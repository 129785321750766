<template>
  <div class="container-profile-mis-pedidos">
    <div v-if="token" class="profile-mis-pedidos">
      <div v-if="orders.actives.length > 0" class="activos-profile-mis-pedidos">
        <div v-if="!visibleCard" class="container-divider-pedidos">
          <p>PEDIDOS ACTIVOS ({{ orders.actives.length }})</p>
          <div class="divider-pedidos" />
        </div>
        <div
          v-for="(tag, index) in orders.actives"
          :key="index + '-en-proceso'"
        >
          <card-mis-pedidos
            v-if="!visibleCard || visibleCard == tag.pedido_id"
            :tag="tag"
            @showPedido="visibleCard = $event"
          />
        </div>
      </div>
      <div
        v-else
        class="text-center d-flex flex-wrap align-content-center"
        style="min-height: 300px"
      >
        <h3 class="w-100">¡Aun no tienes pedidos!</h3>
        <p class="w-100">Añade productos a tu carrito y finaliza la compra.</p>
      </div>
    </div>
    <div v-else class="profile-mis-direcciones py-5">
      <div
        class="container-bag-empty"
        style="height: 50vh; background-color: white"
      >
        <div class="text-center">
          <h3>Aún no has iniciado sesión</h3>
          <p class="fs-6">
            Para poder acceder a este modulo primero debes iniciar sesión.
          </p>
          <div v-if="!token" class="mt-4" @click="$bvModal.show('modal-login')">
            <button class="btn-primary px-4 fs-6">Iniciar sesión</button>
          </div>
        </div>
      </div>
    </div>
    <footer-Returns />
  </div>
</template>
<script>
import CardMisPedidos from '@/components/Profile/CardMisPedidos'
import FooterReturns from '@/components/Returns/FooterReturns'

import { mapState } from 'vuex'
export default {
  components: { CardMisPedidos, FooterReturns },
  data() {
    return {
      token: null,
      enProceso: [
        { color: '#C8D5EF', text: 'En proceso' },
        { color: '#C8D7E5', text: 'Devolución' },
        { color: '#D1EFC8', text: 'Entregado' },
        { color: '#FFC8C8', text: 'Cancelado' },
      ],
      finalizado: [{ color: '#D1EFC8', text: 'Entregado' }],
      visibleCard: null,
    }
  },
  computed: {
    ...mapState('user', ['orders']),
  },
  mounted() {
    this.token = window.localStorage.getItem('accessToken')
  },
}
</script>
