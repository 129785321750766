<template>
  <div>
    <base-profile />
    <div class="container-profile pb-5">
      <profile-mis-pedidos class="container-profile-page" />
    </div>
  </div>
</template>
<script>
import BaseProfile from '@/components/Profile/BaseProfile'
import ProfileMisPedidos from '@/components/Profile/ProfileMisPedidos'

import { mapActions } from 'vuex'
export default {
  components: { BaseProfile, ProfileMisPedidos },
  async mounted() {
    document.title = 'Mis pedidos | DEREK Tienda Online'
    this.$store.state.loaderDerek = true
    await this.getOrders()
    this.$store.state.loaderDerek = false
    // this.$gtag.pageview(this.$route.path)
  },
  methods: {
    ...mapActions('user', ['getOrders']),
  },
}
</script>
