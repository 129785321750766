<template>
  <div :id="'card-pedido-' + tag.pedido_id">
    <div v-if="showDataOrder" class="d-flex align-items-center ms-2 mb-3">
      <div
        style="cursor: pointer; display: flex; align-items-center"
        @click="
          showDataOrder = false
          $emit('showPedido', null)
        "
      >
        <img src="@/assets/icons/arrow-left-simple.svg" class="pe-2" /> Volver
      </div>
    </div>
    <div v-if="!showDataOrder" class="card-mis-pedidos">
      <span
        class="tag-estado-pedido"
        :style="'background-color: ' + '#C8D5EF'"
        >{{ tag.status_title }}</span
      >
      <p class="data-estado-pedido">
        Pedido No. <strong>{{ tag.pedido_id }} </strong> <br />
        Fecha pedido: <strong>{{ getDate(tag.pedido_fecha) }} </strong> <br />
        Valor pedido:
        <strong
          >${{ new Intl.NumberFormat('de-DE').format(tag.pedido_total) }}
        </strong>
        <br />
        Último estado:
        <strong>{{ tag.titulo_estado }} - {{ tag.fecha_ultimo_estado }}</strong>
      </p>
      <div class="d-flex mt-3 my-md-auto">
        <button class="btn-estado-pedido" @click="getDataOrder()">
          <div v-if="!loading">Ver detalles del pedido</div>
          <div v-else class="d-flex justify-content-center">
            Cargando...
            <div class="loader" />
          </div>
        </button>
      </div>
    </div>
    <follow-up-order v-if="showDataOrder" />
  </div>
</template>
<script>
import FollowUpOrder from '@/components/FollowOrder/FollowUpOrder'

import { mapState, mapActions } from 'vuex'
export default {
  components: { FollowUpOrder },
  props: {
    tag: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      showDataOrder: false,
      months: [
        { value: '01', text: 'Enero' },
        { value: '02', text: 'Febrero' },
        { value: '03', text: 'Marzo' },
        { value: '04', text: 'Abril' },
        { value: '05', text: 'Mayo' },
        { value: '06', text: 'Junio' },
        { value: '07', text: 'Julio' },
        { value: '08', text: 'Agosto' },
        { value: '09', text: 'Septiembre' },
        { value: '10', text: 'Octubre' },
        { value: '11', text: 'Noviembre' },
        { value: '12', text: 'Diciembre' },
      ],
    }
  },
  computed: {
    ...mapState('user', ['dataUser', 'dataOrder']),
  },
  watch: {
    dataOrder() {
      if (this.dataOrder == null) {
        this.showDataOrder = false
      }
    },
  },
  methods: {
    ...mapActions('user', ['getInfoOrder']),
    getDate(data) {
      let year = data.split(' ')[0].split('-')[0]
      let month = this.months.find(
        e => e.value == data.split(' ')[0].split('-')[1],
      )
      let day = data.split(' ')[0].split('-')[2]
      return day + ' ' + month.text + ' ' + year
    },
    async getDataOrder() {
      this.loading = true
      await this.getInfoOrder({
        email: this.dataUser.correo,
        pedido_id: this.tag.pedido_id,
      }).then(async () => {
        this.showDataOrder = true
        this.loading = false
        // await setTimeout(()=>{
        //     location.href = '#order-detail';
        // }, 50);
        window.scrollTo(0, 0)
        this.$emit('showPedido', this.tag.pedido_id)
      })
    },
  },
}
</script>
